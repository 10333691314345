<template>
  <common-warpper title="互动问答" en="INTERACTIVE Q&A" :imgSrc="banner">
    <div class="interlocution">
      <ul class="question-lsit" v-if="list">
        <li v-for="(item, i) in list" :key="i">
          <div class="question">{{ item.title }}</div>
          <div class="answer">
            <div class="answer-content" v-html="item.content"></div>
          </div>
        </li>
      </ul>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
  </common-warpper>
</template>
<script>
import CommonWarpper from "@/components/CommonWarpper.vue";
import banner from "@/assets/images/banner/interlocution.jpg";
import { pageByCode } from "@/api/index";

export default {
  name: "Interlocution",
  components: {
    CommonWarpper,
  },
  data() {
    return {
      banner,
      list: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        code: "common_problem",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      pageByCode(this.queryParams).then((res) => {
        this.list = res.data && res.data.records;
        this.total = res.data && res.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/interlocution.scss";
.interlocution {
  padding-top: 20px;
}
</style>
